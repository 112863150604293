var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-row',[(
          _vm.dialogOptions.cameraOptions &&
          _vm.dialogOptions.cameraOptions.cameraList &&
          _vm.dialogOptions.cameraOptions.cameraList.length
        )?_c('v-col',{attrs:{"cols":12}},[_c('Select',{attrs:{"options":_vm.dialogOptions.cameraOptions.cameraList,"replace-fields":{
            lable: 'cameraName',
            value: 'uuid',
          },"allow-clear":false,"container-style":"display: inline-flex;margin-left: 48px;","placeholder":"请选择摄像头"},on:{"set:value":function($event){return _vm.getCamera({
              url: _vm.dialogOptions.cameraOptions.url,
              query: _vm.form,
            })}},model:{value:(_vm.form.cameraUUID),callback:function ($$v) {_vm.$set(_vm.form, "cameraUUID", $$v)},expression:"form.cameraUUID"}})],1):_vm._e(),_c('v-col',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"cols":12}},[(_vm.dialogOptions.videoType !== 'live')?_c('v-carousel',{staticStyle:{"width":"1172px"},attrs:{"height":"619","hide-delimiters":"","show-arrows":_vm.filesLength > 1}},[_vm._l((_vm.attachList),function(attach,i){return _c('v-carousel-item',{key:("video_" + i),staticStyle:{"width":"1172px","height":"619px"}},[_c('video',{staticStyle:{"width":"1172px","height":"619px"},attrs:{"src":_vm.$imgUrl + attach.url,"muted":"muted","loop":"","preload":"auto","controls":""},domProps:{"muted":true}})])}),_vm._l((_vm.imageList),function(image,i){return _c('v-carousel-item',{key:("image_" + i),staticStyle:{"width":"1172px","height":"619px"},attrs:{"src":_vm.$imgUrl + image}})})],2):_c('iframe',{ref:"video",attrs:{"src":(_vm.$businessApi + "/monitor/video/video-play/" + (_vm.dialogOptions.videoType) + "?videoUrl=" + _vm.formatVideoUrl),"width":"1172px","height":"619px","frameborder":"0"}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }